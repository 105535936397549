import SmoothScroll from "smooth-scroll";

const smoothers = document.querySelectorAll('[data-smooth]');
const scrollOptions = { speed: 1240, easing: 'easeOutQuint' };

smoothers.forEach(smoother => {

    smoother.addEventListener('click', function (e) {
        e.preventDefault()

        var scroll = new SmoothScroll();

        const targetName = smoother.getAttribute('data-smooth-target');
        const target = document.querySelector('.' + targetName);

        scroll.animateScroll(
            target,
            '',
            scrollOptions
        )
    })
});