let accordionButton = document.querySelectorAll('.accordion__button');

accordionButton.forEach(button => {
    let accordion = button.parentElement.nextElementSibling
    const defaultAriaLabel = button.getAttribute('aria-label');

    button.addEventListener('click', function () {
        if (accordion.style.maxHeight) {
            accordion.style.maxHeight = null;
            button.setAttribute('aria-label', defaultAriaLabel);
            button.classList.remove('accordion__button_activated');
        } else {
            accordion.style.maxHeight = accordion.scrollHeight + "px";
            button.classList.add('accordion__button_activated');
            button.setAttribute('aria-label', 'Mostra di meno');
        }
    })
});