import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import debounce from 'lodash/debounce';

const body = document.body;
const responsiveValue = body.getAttribute('data-responsive');
const mqlg = window.matchMedia(`(min-width: ${responsiveValue})`);

function setResponsiveClass() {
    if (mqlg.matches) {
        body.classList.remove('is-mobile');
        clearAllBodyScrollLocks();
    } else {
        if (!body.classList.contains('is-mobile')) {
            body.classList.add('is-mobile');
        }
    }
}

setResponsiveClass();

window.addEventListener('resize', debounce(function () {
    setResponsiveClass();
}, 150));