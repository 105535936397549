import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import debounce from 'lodash/debounce';
const hamburger = document.querySelector('.navbar__hamburger');
const navigation = document.querySelector('.navigation');
const navbar = document.querySelector('.navbar');
const responsiveValue = document.body.getAttribute('data-responsive');
const mqlg = window.matchMedia(`(min-width: ${responsiveValue})`);

var isopen = false;

function openCloseMenu() {
    if (document.body.classList.contains('is-mobile')) {
        if (isopen == false) {
            disableBodyScroll(navigation);
            hamburger.classList.add('is-active');
            navigation.classList.add('navigation_open');
            navigation.classList.add('navigation_transitioning');
            isopen = true;
        } else {
            clearAllBodyScrollLocks();

            hamburger.classList.remove('is-active');
            navigation.classList.remove('navigation_open');
            navigation.addEventListener('transitionend', function () {
                navigation.classList.remove('navigation_transitioning');
            }, { once: true });
            isopen = false;
        }
    }
}

function setResponsiveNavHeight() {
    // Setta altezza e top
    if (!mqlg.matches) {
        let navbarHeight = navbar.clientHeight;
        navigation.style.height = `calc(100% - ${navbarHeight}px)`;
        navigation.style.top = `${navbarHeight}px`;
    } else {
        navigation.removeAttribute('style');
    }
}

setResponsiveNavHeight();

window.addEventListener('resize', debounce(function () {
    setResponsiveNavHeight();
}, 150));

hamburger.addEventListener('click', openCloseMenu);