const accettazione = document.querySelector('input[name=terms]');
const checkoutForm = document.querySelector('form[name=checkout]');

function enableDisableForm() {
    const formEls = document.querySelectorAll('form[name=checkout] input:not([name=terms]), form[name=checkout] select, form[name=checkout] textarea, form[name=checkout] button');
    // const formLabels = document.querySelectorAll('form[name=checkout] label');
    // const costumerDetails = document.querySelector('#customer_details');
    formEls.forEach(el => {
        el.setAttribute('disabled', '');
    });

    if (accettazione.checked == true) {
        formEls.forEach(el => {
            el.removeAttribute('disabled');
        });
    } else {
        formEls.forEach(el => {
            el.setAttribute('disabled', '');
        });
    }
}

if (checkoutForm) {
    setTimeout(() => {
        enableDisableForm();
    }, 1000);
    accettazione.addEventListener('change', enableDisableForm);
}

// function enable_disable_form() {
//     if (jQuery('input[name=terms]').prop("checked") == true) {
//         jQuery('form[name=checkout] input, form[name=checkout] select, form[name=checkout] textarea, form[name=checkout] button').not('input[name=terms]').prop("disabled", false);
//     }
//     else {
//         jQuery('form[name=checkout] input, form[name=checkout] select, form[name=checkout] textarea, form[name=checkout] button').not('input[name=terms]').prop("disabled", true);
//     }
// }
// setTimeout(function () { enable_disable_form(); }, 1000);
// jQuery('input[name=terms]').change(enable_disable_form);