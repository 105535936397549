import debounce from 'lodash/debounce';

function ratiologos(options) {
    let images = document.querySelectorAll(`${options.element}`);

    images.forEach(function (el) {
        el.style.display = "none";
        el.style.maxWidth = "100%";
    });


    function adjustImageWidth(image) {
        let imageParentWidth = image.parentElement.offsetWidth;

        if (options.base) {
            var widthBase = `${options.base}`;
        } else {
            var widthBase = imageParentWidth / 2.4;
        }
        if (options.scale) {
            var scaleFactor = `${options.scale}`;
        } else {
            var scaleFactor = 0.525;
        }
        var imageRatio = image.naturalWidth / image.naturalHeight;

        let resultingWidth = Math.pow(imageRatio, scaleFactor) * widthBase;
        image.style.width = Math.round((resultingWidth / imageParentWidth) * 100) + '%';
        image.style.display = "block";
    }

    images.forEach(function (image) {
        adjustImageWidth(image);
    });
}

ratiologos({
    element: '.sponsors__item img'
});

// window.addEventListener('resize', debounce(function () {
//     ratiologos({
//         element: '.sponsors__item img'
//     });
// }, 150));